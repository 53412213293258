export const provinceList = [
  'La Coruña',
  'Alicante',
  'Albacete',
  'Almería',
  'Álava',
  'Asturias',
  'Ávila',
  'Badajoz',
  'Barcelona',
  'Vizcaya',
  'Burgos',
  'Cáceres',
  'Cádiz',
  'Cantabria',
  'Castellón',
  'Ceuta',
  'Ciudad Real',
  'Córdoba',
  'Cuenca',
  'Guipúzcoa',
  'Gerona',
  'Granada',
  'Guadalajara',
  'Huelva',
  'Huesca',
  'Baleares',
  'Jaén',
  'La Rioja',
  'Las Palmas',
  'León',
  'Lérida',
  'Lugo',
  'Madrid',
  'Málaga',
  'Melilla',
  'Murcia',
  'Navarra',
  'Orense',
  'Palencia',
  'Pontevedra',
  'Salamanca',
  'Santa Cruz de Tenerife',
  'Segovia',
  'Sevilla',
  'Soria',
  'Tarragona',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Zamora',
  'Zaragoza'
];

export const tallaSizes = ['S', 'M', 'L', 'XL', '2XL', '3XL'];
export const profileFileType = ['jpg', 'jpeg', 'png'];

export const CREATE_ADMIN_SUCCESS = 'El nuevo usuario fue creado exitosamente';
export const UPDATE_ADMIN_SUCCESS = 'La nueva usuario se actualizó con éxito';
export const SOMETHING_ERROR = 'Algo salió mal';
export const LOADING = 'Cargando...';

export const DELETE_CONFIRM = '¿Realmente deseas desasociar a esta persona?';
export const CONFIRM_TITLE = 'Confirmar modal?';

export const SUCCESS_MSG = 'Éxito';
export const FAILED_MSG = 'Fallida';

export const APPLICATION_SUBMIT = 'Enviar solicitud';
export const CERTIFICATION_MISSING =
  'Por favor cargue el documento de certificación.';

export const categoryList = ['Cat I', 'Cat II', 'Cat III'];
